import React, { useState, useEffect } from "react"
import Footer from "../components/footer"
import Video from "../components/video"
import Header from "../components/header"
import AboutPages from "./aboutPages"
import Seo from "../components/seo"

export default function LandingHome() {
    const [pressVideo, setPressVideo] = useState(false);
    return (
        <div>
            <Seo />
            <Header />
            <section style={styles.sectionStyle}>
                <Video
                    videoSrcURL="https://www.youtube.com/embed/OJ2b-LkJ0Uo?autoplay=1&showinfo=0&rel=0"
                    videoTitle="Official Music Video on YouTube"
                    videoStatus={pressVideo}
                    updateVideoStatus={(state) => setPressVideo(state)}
                />
            </section>
            <AboutPages />
            <Footer />

        </div>
    );
}
const styles = {
    sectionStyle: {
        borderTop: `2px solid rgba(0,173,238,1)`
    }
} as const