import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const FollowImage = (props) => (
  <div style={{
    width: "100%",
    height: "100%"
  }} onClick={() => window.open(props.link, "_blank", "noopener,noreferrer")}>
    <Img
      fluid={props.Image}
      imgStyle={{ objectFit: "contain" }}
      alt=""
      style={{
        marginLeft: 3,
        cursor: 'pointer',
      }}
    />
  </div>
);

const Footer = (props) => {
  const data = useStaticQuery(imageQuery);
  const styles = {
    container: {
      width: `100%`,
      height: `15%`,
      objectFit: "contain",
      objectPosition: "center",
    },
    mainContainer: {
      backgroundColor: props.headerColor ? props.headerColor : "#f1f1f2"
    },
    secondMainDiv: {
      display: "flex",
      flexDirection: `row`,
      justifyContent: `center`,
      paddingTop: 25,
      paddingBottom: 25,
    },
    copyRightStyle: {
      display: "flex",
      flexDirection: `column`,
      justifyContent: `center`,
    },
    followContainer: {
      display: "flex",
      flexDirection: `column`,
      justifyContent: `center`,
      marginLeft: `65%`,
      marginRight: 10,
      marginBottom: 10
    },
    followDiv: {
      display: "flex",
      flexDirection: `row`,
      marginTop: 18,
      width: `100%`,
    }
  } as const
  return (
    <div style={styles.container} id="Contact">
      <div style={styles.mainContainer}>
        <div style={styles.secondMainDiv}>
          <div style={styles.copyRightStyle}>
            <text>Copyright &#169;2024 Funbits Technologies</text>
          </div>
          <div
            style={styles.followContainer}>
            <text className="Follow-us">Follow us</text>
            <div style={styles.followDiv}>
              <FollowImage
                Image={data.facebook.childImageSharp.fluid}
                link="https://www.facebook.com/FunBitsOntario"
              />
              <FollowImage
                Image={data.youtube.childImageSharp.fluid}
                link="https://www.youtube.com/channel/UCk2Yb14TlCZrkO9O4OVqpEQ"
              />
              <FollowImage
                Image={data.twitter.childImageSharp.fluid}
                link="https://twitter.com/TheWorkFun"
              />
              <FollowImage
                Image={data.linkedin.childImageSharp.fluid}
                link="https://www.linkedin.com/organization-guest/company/funbits"
              />
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

const imageQuery = graphql`
      query {
        header: file(relativePath: { eq: "header_Bar.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rating: file(relativePath: { eq: "RatingBar.png" }) {
          childImageSharp {
            fluid(maxWidth: 495) {
              srcWebp
            }
          }
        }
        facebook: file(relativePath: { eq: "facebook.png" }) {
          childImageSharp {
            fluid(maxWidth: 512, maxHeight: 512) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        youtube: file(relativePath: { eq: "youtube.png" }) {
          childImageSharp {
            fluid(maxWidth: 512, maxHeight: 512) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        twitter: file(relativePath: { eq: "twitter.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        linkedin: file(relativePath: { eq: "linkedin.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
`

export default Footer;

