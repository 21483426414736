import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Img from "gatsby-image"

const Video = ({
  videoSrcURL,
  videoTitle,
  videoStatus,
  updateVideoStatus,
  ...props
}) => {
  const data = useStaticQuery(imageQuery)

  return (< div className="video" >
    { videoStatus === false ? (
      <div id="openVideo" className="about-video"
        onClick={() => updateVideoStatus(true)}>
        <Img
          fluid={data.video.childImageSharp.fluid}
          className="VideoPlaceholder"
          fadeIn={false}
          style={styles.imgStyle}
          alt=""
        />
      </div>
    ) : (
        <iframe
          src={videoSrcURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          style={styles.iframeStyle}
        />
      )
    }
  </div >
  )
}

const imageQuery = graphql`
  query {
    video: file(relativePath: { eq: "video-placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Video;
const styles = {
  imgStyle: {
    width: `100%`,
    height: `100%`,
    marginBottom: -9,
  },
  iframeStyle: {
    width: `100%`,
    height: 810,
    marginBottom: -9,
  }
} as const